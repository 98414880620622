export function findSalesRequest({ startDate, endDate }) {
  return {
    type: '@data/FIND_SALES_REQUEST',
    payload: { startDate, endDate },
  };
}

export function findSalesFailure() {
  return {
    type: '@data/FIND_SALES_FAILURE',
  };
}

export function findSalesSuccess(data) {
  return {
    type: '@data/FIND_SALES_SUCCESS',
    payload: { data },
  };
}
