import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../../assets/logo.png';
import { signRequest } from '../../store/modules/auth/actions';

import Loading from '../../components/Loading';
import useStyles from './styles';

function Sign() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const emailDefault =
    process.env.NODE_ENV === 'development'
      ? 'desenvolvimento@gestorfood.com'
      : '';

  const passwordDefault =
    process.env.NODE_ENV === 'development' ? '123456' : '';

  const [email, setEmail] = useState(emailDefault);
  const [password, setPassword] = useState(passwordDefault);

  const { loading } = useSelector(state => state.auth);

  const handleOnSubmit = async e => {
    e.preventDefault();
    dispatch(signRequest(email, password));
  };

  return (
    <>
      <div className={classes.root}>
        <img className={classes.logo} src={logo} alt="logo" />
        <div className={classes.sub}>
          <h1>Informe email e senha</h1>
          <h2>Para ter acesso a plataforma</h2>
          <span />
        </div>
        <form onSubmit={handleOnSubmit} className={classes.sign}>
          <input
            className={classes.input}
            type="text"
            placeholder="Email"
            value={email}
            onChange={event => setEmail(event.target.value)}
          />
          <input
            className={classes.input}
            type="password"
            placeholder="Senha"
            value={password}
            onChange={event => setPassword(event.target.value)}
          />
          <button className={classes.submit} type="submit">
            acessar
          </button>
        </form>
      </div>

      <Loading loading={loading} />
    </>
  );
}

export { Sign };
