import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    width: '300px',
    backgroundColor: '#fff',
  },
  custom: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',

    '& span': {
      fontWeight: '600',
      fontSize: '0.8rem',
      lineHeight: '0.8rem',
      letterSpacing: '0.06rem',
      textTransform: 'uppercase',
    },
  },
  nested: {
    paddingLeft: theme.spacing(4),
    '& span': {
      fontSize: '0.8rem',
      lineHeight: '0.8rem',
      letterSpacing: '0.06rem',
      textTransform: 'uppercase',
    },
  },
}));

export default useStyles;
