import React from 'react';
import { useSelector } from 'react-redux';
import { PieChart, Pie, Cell } from 'recharts';

import useStyles from './styles';

const COLORS = [
  '#4caf50',
  '#2196f3',
  '#ff9800',
  '#009688',
  '#f44336',
  '#9e9e9e',
];

function SalesByReceipt() {
  const classes = useStyles();

  const { receipts } = useSelector(state => state.data);
  const data = [...receipts]
    .sort((a, b) => b.value - a.value)
    .map((item, index) => {
      return {
        ...item,
        formatedValue: item.value.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }),
        color: COLORS[index],
      };
    });

  const total = data
    .reduce((acc, item) => acc + item.value, 0)
    .toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });

  return (
    receipts.length > 0 && (
      <div className={classes.root}>
        <span className={classes.title}>recebimentos</span>

        <PieChart width={360} height={360}>
          <Pie data={data} fill="#8884d8" legendType="line">
            {data.map((item, index) => (
              <Cell key={JSON.stringify(item)} fill={COLORS[index]} />
            ))}
          </Pie>
        </PieChart>

        <ul className={classes.list}>
          {data.map(item => (
            <li
              key={item.id}
              className={classes.item}
              style={{
                color: item.color,
              }}
            >
              <span>{item.name}</span>
              <span>{item.formatedValue}</span>
            </li>
          ))}
        </ul>

        <div className={classes.total}>
          <span>total</span>
          <span>{total}</span>
        </div>
      </div>
    )
  );
}

export { SalesByReceipt };
