import md5 from 'md5';
import produce from 'immer';

const INITIAL_STATE = {
  loading: false,
  company: null,
  user: null,
  signed: false,
  menuModal: false,
};

const makeCredentials = (userId, companyId) => {
  const credentials = {
    user: userId,
    company: companyId,
    token: md5(`05-${userId}-04-${companyId}-1988`).toUpperCase(),
  };

  window.localStorage.setItem('credentials', JSON.stringify(credentials));
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@auth/SIGN_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@auth/SIGN_FAILURE': {
        draft.loading = false;
        break;
      }

      case '@auth/SIGN_SUCCESS': {
        const { user } = action.payload;
        const { companies } = user;
        const company = companies[0];

        draft.user = user;
        draft.company = company;
        draft.signed = true;
        draft.loading = false;

        makeCredentials(draft.user.id, draft.company.id);
        break;
      }

      case '@auth/MENU': {
        draft.menuModal = !draft.menuModal;
        break;
      }

      case '@auth/CHANGE_COMPANY': {
        const { company: id } = action.payload;
        draft.company = draft.user.companies.find(company => company.id === id);
        makeCredentials(draft.user.id, draft.company.id);
        draft.menuModal = false;
        break;
      }

      case '@auth/SIGN_OUT': {
        draft.loading = false;
        draft.user = null;
        draft.signed = false;
        draft.menuModal = false;
        break;
      }

      case '@starting': {
        draft.loading = false;
        draft.menuModal = false;
        break;
      }

      default:
    }
  });
}
