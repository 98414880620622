export function signRequest(email, password) {
  return {
    type: '@auth/SIGN_REQUEST',
    payload: { email, password },
  };
}

export function signFailure() {
  return {
    type: '@auth/SIGN_FAILURE',
  };
}

export function signSuccess(user) {
  return {
    type: '@auth/SIGN_SUCCESS',
    payload: { user },
  };
}

export function changeCompany(company) {
  return {
    type: '@auth/CHANGE_COMPANY',
    payload: { company },
  };
}

export function menu() {
  return {
    type: '@auth/MENU',
  };
}

export function signOut() {
  return {
    type: '@auth/SIGN_OUT',
  };
}

export function starting() {
  return {
    type: '@starting',
  };
}
