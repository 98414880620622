import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import SwipeableViews from 'react-swipeable-views';

import { SalesByModule } from '../../components/charts/SalesByModule';
import { SalesByReceipt } from '../../components/charts/SalesByReceipt';
import { SalesBySeller } from '../../components/charts/SalesBySeller';
import { SalesByCategory } from '../../components/charts/SalesByCategory';
import { TabPanel } from '../../components/TabPanel';
import { Menu } from '../../components/Menu';
import { menu } from '../../store/modules/auth/actions';

import useStyles from './styles';
import { FilterByDay } from '../../components/filters/FilterByDay';
import { FilterContainer } from '../../components/filters/FilterContainer';
import { FilterByWeek } from '../../components/filters/FilterByWeek';
import { FilterByMonth } from '../../components/filters/FilterByMonth';
import { FilterByYear } from '../../components/filters/FilterByYear';
import { SalesByProduct } from '../../components/charts/SalesByProduct';
import Loading from '../../components/Loading';

function Home() {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [tabIndex, setTabIndex] = useState(0);
  const { company } = useSelector(state => state.auth);

  const handleChange = (_, newValue) => {
    setTabIndex(newValue);
  };

  const handleChangeIndex = index => {
    setTabIndex(index);
  };

  const hanldeMenu = () => {
    dispatch(menu());
  };

  return (
    <div className={classes.root}>
      <AppBar position="fixed">
        <Toolbar variant="regular">
          <IconButton
            color="inherit"
            aria-label="menu"
            onClick={() => hanldeMenu()}
          >
            <MenuIcon />
          </IconButton>
          {company && <span className={classes.title}>{company.name}</span>}
        </Toolbar>
      </AppBar>

      <main>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={tabIndex}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={tabIndex} index={0}>
            <section className={classes.content}>
              <SalesByModule />
              <SalesByReceipt />
              <SalesBySeller />
              <SalesByCategory />
              <SalesByProduct />
            </section>
          </TabPanel>
          <TabPanel value={tabIndex} index={1}>
            <section className={classes.content}>
              <SalesByModule />
              <SalesByReceipt />
              <SalesBySeller />
              <SalesByCategory />
              <SalesByProduct />
            </section>
          </TabPanel>
          <TabPanel value={tabIndex} index={2}>
            <section className={classes.content}>
              <SalesByModule />
              <SalesByReceipt />
              <SalesBySeller />
              <SalesByCategory />
              <SalesByProduct />
            </section>
          </TabPanel>
          <TabPanel value={tabIndex} index={3}>
            <section className={classes.content}>
              <SalesByModule />
              <SalesByReceipt />
              <SalesBySeller />
              <SalesByCategory />
              <SalesByProduct />
            </section>
          </TabPanel>
        </SwipeableViews>
      </main>

      <Paper className={classes.tabs}>
        <FilterContainer>
          {tabIndex === 0 && <FilterByDay />}
          {tabIndex === 1 && <FilterByWeek />}
          {tabIndex === 2 && <FilterByMonth />}
          {tabIndex === 3 && <FilterByYear />}
        </FilterContainer>

        <Tabs
          value={tabIndex}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="hoje" />
          <Tab label="semanal" />
          <Tab label="mensal" />
          <Tab label="anual" />
        </Tabs>
      </Paper>

      <Menu />
      <Loading />
    </div>
  );
}

export { Home };
