import React from 'react';
import PropTypes from 'prop-types';

import useStyles from './styles';

function Dashboard({ children }) {
  const classes = useStyles();
  return <div className={classes.root}>{children}</div>;
}

export { Dashboard };

Dashboard.propTypes = {
  children: PropTypes.element.isRequired,
};
