import produce from 'immer';

const INITIAL_STATE = {
  loading: false,
  modules: [],
  receipts: [],
  sellers: [],
  categories: [],
  products: [],
};

export default function data(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@data/FIND_SALES_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@data/FIND_SALES_FAILURE': {
        draft.loading = false;
        break;
      }

      case '@data/FIND_SALES_SUCCESS': {
        const {
          data: { modules, receipts, sellers, categories, products },
        } = action.payload;

        draft.modules = modules.map(module => {
          return {
            ...module,
            value: Number(module.value.toFixed(2)),
          };
        });
        draft.receipts = receipts.map(receipt => {
          return {
            ...receipt,
            value: Number(receipt.value.toFixed(2)),
          };
        });
        draft.sellers = sellers.map(seller => {
          return {
            ...seller,
            name: seller.id === 0 ? 'outros' : seller.name,
            value: Number(seller.value.toFixed(2)),
          };
        });
        draft.categories = categories.map(category => {
          return {
            ...category,
            value: Number(category.value.toFixed(2)),
          };
        });
        draft.products = products.map(product => {
          return {
            ...product,
            value: Number(product.value.toFixed(2)),
          };
        });

        draft.loading = false;
        break;
      }

      default:
    }
  });
}
