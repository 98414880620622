import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    padding: '1rem',
    border: '1px solid #ccc',
    borderRadius: '0.5rem',
    boxShadow: '0 0 5px #ccc',
  },
  title: {
    width: '100%',
    fontSize: '0.9rem',
    textTransform: 'uppercase',
  },
  list: {
    width: '100%',
    minHeight: '160px',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.25rem',
  },
  item: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',

    fontSize: '0.7rem',
    textTransform: 'uppercase',
  },
  total: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',

    fontSize: '0.8rem',
    textTransform: 'uppercase',
  },
}));

export default useStyles;
