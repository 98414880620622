import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addDays, addHours, format } from 'date-fns';

import IconButton from '@material-ui/core/IconButton';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import { ptBR } from 'date-fns/locale';
import useStyles from './styles';
import { findSalesRequest } from '../../../store/modules/data/actions';

function removeAccents(str) {
  str = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  return str;
}

function FilterByDay() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { company } = useSelector(state => state.auth);

  const currentDate = new Date();
  const currentDay = currentDate.getDate();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  const [date, setDate] = useState(
    new Date(currentYear, currentMonth, currentDay)
  );

  const handleNextDay = () => {
    setDate(addDays(date, 1));
  };

  const handlePreviousDay = () => {
    setDate(addDays(date, -1));
  };

  useEffect(() => {
    const start = addHours(date, 5);
    const end = addHours(date, 29);
    const startString = format(start, 'yyyy-MM-dd HH:mm:ss');
    const endString = format(end, 'yyyy-MM-dd HH:mm:ss');

    const timeout = setTimeout(() => {
      dispatch(
        findSalesRequest({ startDate: startString, endDate: endString })
      );
    }, 3000);

    return () => clearTimeout(timeout);
  }, [date, company, dispatch]);

  return (
    <div className={classes.root}>
      <IconButton onClick={handlePreviousDay}>
        <NavigateBeforeIcon />
      </IconButton>
      <span className={classes.title}>
        {removeAccents(
          format(date, 'dd/MM/yyyy - E', {
            locale: ptBR,
          })
        )}
      </span>
      <IconButton onClick={handleNextDay}>
        <NavigateNextIcon />
      </IconButton>
    </div>
  );
}

export { FilterByDay };
