import { urlBase } from './base';

export const create = async ({ email, password }) => {
  const url = `${urlBase}/session`;

  if (url) {
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({ email, password }),
    });

    return response.json();
  }

  return null;
};
