import React from 'react';
import PropTypes from 'prop-types';

import useStyles from './styles';

function FilterContainer({ children }) {
  const classes = useStyles();

  return <div className={classes.root}>{children}</div>;
}

export { FilterContainer };

FilterContainer.propTypes = {
  children: PropTypes.node.isRequired,
};
