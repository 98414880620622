import { urlBase, userCredentials } from './base';

export const findSales = async ({ startDate, endDate }) => {
  const url = `${urlBase}/dashboard/findsales`;
  const credentials = userCredentials();

  if (credentials) {
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        credentials,
        data: { startDate, endDate },
      }),
    });

    return response.json();
  }

  return null;
};
