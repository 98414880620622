import React, { useState } from 'react';
import Drawer from '@material-ui/core/Drawer';
import { useDispatch, useSelector } from 'react-redux';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { changeCompany, menu, signOut } from '../../store/modules/auth/actions';

import useStyles from './styles';

function Menu() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { menuModal, user } = useSelector(state => state.auth);

  const hanldeMenu = () => {
    dispatch(menu());
  };

  const handleClick = () => {
    setOpen(!open);
  };

  const handleCompanyClick = company => {
    dispatch(changeCompany(company));
  };

  const handleSignOut = () => {
    dispatch(signOut());
  };

  return (
    <Drawer anchor="left" open={menuModal} onClose={hanldeMenu}>
      <div className={classes.root}>
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          className={classes.root}
        >
          <ListItem button onClick={handleClick} className={classes.custom}>
            <span>empresas</span>
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {user.companies.map(company => (
                <ListItem
                  key={company.id}
                  button
                  className={classes.nested}
                  onClick={() => handleCompanyClick(company.id)}
                >
                  <span>{company.name}</span>
                </ListItem>
              ))}
            </List>
          </Collapse>
          <ListItem button onClick={handleSignOut} className={classes.custom}>
            <span>sair</span>
          </ListItem>
        </List>
      </div>
    </Drawer>
  );
}

export { Menu };
