import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addHours, addWeeks, format, startOfWeek } from 'date-fns';

import IconButton from '@material-ui/core/IconButton';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import { ptBR } from 'date-fns/locale';
import useStyles from './styles';
import { findSalesRequest } from '../../../store/modules/data/actions';

function removeAccents(str) {
  str = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  return str;
}

function FilterByWeek() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { company } = useSelector(state => state.auth);

  const currentDate = new Date();
  const currentDay = currentDate.getDate();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  const [date, setDate] = useState(
    new Date(currentYear, currentMonth, currentDay)
  );

  const [startDate, setStartDate] = useState(addHours(startOfWeek(date), 29));
  const [endDate, setEndDate] = useState(
    addHours(startOfWeek(addWeeks(date, 1)), 29)
  );

  const handleNextWeek = () => {
    setDate(addWeeks(date, 1));
  };

  const handlePreviousWeek = () => {
    setDate(addWeeks(date, -1));
  };

  useEffect(() => {
    const start = addHours(startOfWeek(date), 29);
    const end = addHours(startOfWeek(addWeeks(date, 1)), 29);
    const startString = format(start, 'yyyy-MM-dd HH:mm:ss');
    const endString = format(end, 'yyyy-MM-dd HH:mm:ss');

    setStartDate(start);
    setEndDate(end);

    const timeout = setTimeout(() => {
      dispatch(
        findSalesRequest({ startDate: startString, endDate: endString })
      );
    }, 3000);

    return () => clearTimeout(timeout);
  }, [date, company, dispatch]);

  return (
    <div className={classes.root}>
      <IconButton onClick={handlePreviousWeek}>
        <NavigateBeforeIcon />
      </IconButton>
      <span className={classes.title}>
        {`${removeAccents(
          format(startDate, 'dd/MM/yyyy', {
            locale: ptBR,
          })
        )} - ${removeAccents(
          format(endDate, 'dd/MM/yyyy', {
            locale: ptBR,
          })
        )}`}
      </span>
      <IconButton onClick={handleNextWeek}>
        <NavigateNextIcon />
      </IconButton>
    </div>
  );
}

export { FilterByWeek };
