import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    minHeight: '3rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '1rem',
  },
  title: {
    fontWeight: '600',
    fontSize: '0.8rem',
    lineHeight: '0.8rem',
    letterSpacing: '0.06rem',
    textTransform: 'uppercase',
  },
  button: {
    width: '2rem',
    height: '2rem',
    borderRadius: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    cursor: 'pointer',
    transition: 'all 0.3s ease-in-out',

    '&:hover': {
      backgroundColor: '#ccc',
    },
  },
}));

export default useStyles;
