import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { findSales } from '../../../services/dashboard';
import { findSalesFailure, findSalesSuccess } from './actions';

export function* sales({ payload }) {
  try {
    const { startDate, endDate } = payload;
    const result = yield call(findSales, { startDate, endDate });
    if (result.success) {
      yield put(findSalesSuccess(result.data));
    } else {
      yield put(findSalesFailure());
      toast.error(result.message);
    }
  } catch (err) {
    yield put(findSalesFailure());
    toast.error('Houve um erro inesperado. Tente novamente');
  }
}

export default all([takeLatest('@data/FIND_SALES_REQUEST', sales)]);
