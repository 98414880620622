import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    padding: '4rem 0 6rem 0',
  },
  content: {
    padding: '1rem',
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
    gap: '2rem 1rem',
  },
  tabs: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    left: 0,
  },
  title: {
    fontWeight: '600',
    fontSize: '0.8rem',
    lineHeight: '0.8rem',
    letterSpacing: '0.06rem',
    textTransform: 'uppercase',
  },
}));

export default useStyles;
