import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import useStyles from './styles';

function Loading() {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const { loading: auth } = useSelector(state => state.auth);
  const { loading: data } = useSelector(state => state.data);

  useEffect(() => {
    const loading = auth || data;

    if (loading) {
      setOpen(loading);
    }
    const timeout = setTimeout(() => {
      setOpen(auth || data);
    }, 2000);

    return () => clearTimeout(timeout);
  }, [auth, data]);

  return (
    <Backdrop className={classes.backdrop} open={open}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

export default Loading;
