import React from 'react';
import { useSelector } from 'react-redux';
import { PieChart, Pie, Cell } from 'recharts';

import useStyles from './styles';

const COLORS = [
  '#4caf50',
  '#2196f3',
  '#ff9800',
  '#009688',
  '#f44336',
  '#9e9e9e',
];

function SalesByCategory() {
  const classes = useStyles();
  const amount = 5;

  const { categories } = useSelector(state => state.data);
  const list = [...categories];
  const bests = list.sort((a, b) => b.value - a.value).slice(0, amount);
  const others = list
    .sort((a, b) => b.value - a.value)
    .slice(amount, list.length);

  if (others.length > 0) {
    bests.push({
      id: 0,
      name: 'outros',
      value: others.reduce((acc, item) => acc + item.value, 0),
    });
  }

  const data = bests.map((item, index) => {
    return {
      ...item,
      formatedValue: item.value.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }),
      color: COLORS[index],
    };
  });

  const total = data
    .reduce((acc, item) => acc + item.value, 0)
    .toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });

  return (
    categories.length > 0 && (
      <div className={classes.root}>
        <span className={classes.title}>categorias</span>

        <PieChart width={360} height={360}>
          <Pie data={data} fill="#8884d8" legendType="line">
            {data.map((item, index) => (
              <Cell key={JSON.stringify(item)} fill={COLORS[index]} />
            ))}
          </Pie>
        </PieChart>

        <ul className={classes.list}>
          {data.map(item => (
            <li
              key={item.id}
              className={classes.item}
              style={{
                color: item.color,
              }}
            >
              <span>{item.name}</span>
              <span>{item.formatedValue}</span>
            </li>
          ))}
        </ul>

        <div className={classes.total}>
          <span>total</span>
          <span>{total}</span>
        </div>
      </div>
    )
  );
}

export { SalesByCategory };
