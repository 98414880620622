import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    width: '100vw',
    minHeight: '100vh',
    background: '#eceff1',
  },
});

export default useStyles;
